import React, { useState } from "react";

function EmailSignupForm() {
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform form validation and submission logic here
    // For example, send the email to your server or store it in a database

    // Reset the form after submission
    setEmail("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Email:
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
      </label>
      <button type="submit">Sign Up</button>
    </form>
  );
}

export default EmailSignupForm;
