// Slide.js
import React from "react";
import crowd from "./images/crowd.jpg";
import pdf from "./images/clint-evans__namm-show-registration-sf-lightning-case-study.pdf"

const SlideMusic = ({ title, description, backgroundImage, image }) => {
  const slideStyle = {
    position: "relative",
  };

  // const contentStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   textAlign: "center",
  // };

  return (
    <div className="slide" style={slideStyle}>
      <div className="slide__2col-full">
        <div className="slide__2col-full--img2">
          <div className="slide__2col-full--content">
            <div className="slide__number">03</div>
            <div className="slide__2col-full--content">
              <h2>The NAMM Show Registration</h2>
            </div>
            <div className="slide__description">
              <p>A web app for the world's largest music tradeshow. Every registrant for the event goes through this portal.</p>
              <button>
                <a
                  target="_blank"
                  rel="nofollow"
                  href="https://registration.namm.org/"
                >
                  View App
                </a>
              </button>
              <br/>
              <button style={{ marginTop: '18px' }}>
                <a
                  target="_blank"
                  rel="nofollow"
                  href={pdf}
                >
                  View Internal Case Study
                </a>
              </button>
            </div>
          </div>

          <div className="slide__2col-full--img2-img">
            <img src={crowd} alt="The NAMM Show crowd" />
          </div>
        </div>

        <div className="slide__2col-full--splash">
          <div className="slide__2col-full--inner"></div>
        </div>
      </div>
    </div>
  );
};

export default SlideMusic;
