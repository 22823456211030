import React from "react";

function BlogTeaser() {
  return (
    <section>
      <div className="grid">
        <div className="blog-teaser">
          <div className="blog-teaser__inner">
            <div className="blog-teaser__card">
              <div className="blog-teaser__img">
                <img src="#" alt="#" title="#" />
              </div>
              <div className="blog-teaser__content">
                <div className="blog-teaser__title">
                  <h3>
                    <a href="#">Blog Title</a>
                  </h3>
                </div>
                <div className="blog-teaser__category">Figma</div>
                <div className="blog-teaser__date">June 15, 2023</div>
                <div className="blog-teaser__intro">
                  In the realm of design and development, we seamlessly fuse
                  creativity and technical expertise. Our production team,
                  comprising skilled professionals, is dedicated to bringing our
                  clients' vision to life with meticulous attention to detail.
                  From ideation to execution, we collaborate closely, ensuring
                  the most impactful and creative realization of their digital
                  presence, delivering a seamless and visually stunning end
                  product.
                </div>
              </div>
            </div>
            <div className="blog-teaser__card">
              <div className="blog-teaser__img">
                <img src="#" alt="#" title="#" />
              </div>
              <div className="blog-teaser__content">
                <div className="blog-teaser__title">
                  <h3>
                    <a href="#">Blog Title</a>
                  </h3>
                </div>
                <div className="blog-teaser__category">Figma</div>
                <div className="blog-teaser__date">June 15, 2023</div>
                <div className="blog-teaser__intro">
                  In the realm of design and development, we seamlessly fuse
                  creativity and technical expertise. Our production team,
                  comprising skilled professionals, is dedicated to bringing our
                  clients' vision to life with meticulous attention to detail.
                  From ideation to execution, we collaborate closely, ensuring
                  the most impactful and creative realization of their digital
                  presence, delivering a seamless and visually stunning end
                  product.
                </div>
              </div>
            </div>
            <div className="blog-teaser__card">
              <div className="blog-teaser__img">
                <img src="#" alt="#" title="#" />
              </div>
              <div className="blog-teaser__content">
                <div className="blog-teaser__title">
                  <h3>
                    <a href="#">Blog Title</a>
                  </h3>
                </div>
                <div className="blog-teaser__category">Figma</div>
                <div className="blog-teaser__date">June 15, 2023</div>
                <div className="blog-teaser__intro">
                  In the realm of design and development, we seamlessly fuse
                  creativity and technical expertise. Our production team,
                  comprising skilled professionals, is dedicated to bringing our
                  clients' vision to life with meticulous attention to detail.
                  From ideation to execution, we collaborate closely, ensuring
                  the most impactful and creative realization of their digital
                  presence, delivering a seamless and visually stunning end
                  product.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogTeaser;
