import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import HomeHero from "./HomeHero";
import CorePrincipals from "./CorePrincipals";
import { Helmet } from "react-helmet-async";
import Work from "./Work";
import Tools from "./Tools";
import Footer from "./Footer";
import BlogTeaser from "./BlogTeaser";

function Home() {
  const boxRef = useRef(null);

  useEffect(() => {
    // Run the gsap animation when the component mounts
    if (boxRef.current) {
      gsap.fromTo(boxRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Clint Evans | Front End Developer & UX Designer </title>
        <link rel="canonical" href="https://www.clintwes.com" />
        <meta
          name="description"
          content="Clint Evans is Front End Developer and UX Designer based in San Diego, CA."
        />
      </Helmet>
      <div>
        <HomeHero />
        <CorePrincipals />
        <Work />
        {/* <Tools /> */}
        {/* <BlogTeaser /> */}
      </div>
    </>
  );
}

export default Home;
