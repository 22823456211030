// Slide.js
import React from "react";
import apolis from "./images/Portfolio-Cover.jpg";
import portfolio from "./images/clint-evans__sf-lightning-case-study.pdf";

const SlideLightning = ({ title, description, backgroundImage, image }) => {
  const slideStyle = {
    position: "relative",
  };

  // const contentStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   textAlign: "center",
  // };

  return (
    <div className="slide slide-lighting slide-border__dark" style={slideStyle}>
      <div className="content center-content">
        <div className="slide__3col-centered">
          <div className="slide__3col-centered--title2">
            <div className="slide__number">02</div>
            <h2 className="slide__title">NAMM 2024 Case Study</h2>
            <button>
              <a
                target="_blank"
                href={portfolio}
              >
                View Case Study
              </a>
            </button>
          </div>

          <div className="slide__3col-centered--info2">
            <div className="slide__3col-centered--img3">
              <img src={apolis} alt="Cloud 1" />
            </div>
          </div>

          <div className="slide__3col-centered--description">
            <p>
                Simplifying the Badge Reprint Process in Salesforce Lightning for The NAMM Show.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideLightning;
