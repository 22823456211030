import React from "react";
import { Helmet } from "react-helmet-async";
function FelipePantoneProject() {
  return (
    <>
      <Helmet>
        <title>PGS Homeloans Project</title>
        <link
          rel="canonical"
          href="https://www.clintwes.com/projects/pgs-homeloans"
        />
        <meta
          name="description"
          content="PGS Homeloans needed a webapp that put all of there content behind a paywall and needed various roles that determined what content was shown to whom."
        />
      </Helmet>
      <div>
        <div className="page-hero namm">
          <div className="about__title">
            <h1>NAMM Registration</h1>
          </div>
          <svg
            id="Grid_copy"
            data-name="Grid copy"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1917.19 273.88"
          >
            <polygon points="1917.19 182.59 1917.19 273.88 1825.9 273.88 1825.9 182.59 1734.6 182.59 1734.6 91.29 1734.6 0 1825.9 0 1825.9 91.29 1917.19 91.29 1917.19 182.59" />
            <polygon points="1734.6 182.59 1734.6 273.88 1643.31 273.88 1552.01 273.88 1460.72 273.88 1369.42 273.88 1278.13 273.88 1186.83 273.88 1186.83 182.59 1095.54 182.59 1095.54 91.29 1186.83 91.29 1278.13 91.29 1278.13 182.59 1369.42 182.59 1369.42 91.29 1460.72 91.29 1460.72 182.59 1552.01 182.59 1552.01 91.29 1552.01 0 1643.31 0 1643.31 91.29 1643.31 182.59 1734.6 182.59" />
            <rect x="1278.13" width="91.29" height="91.29" />
            <polygon points="1095.54 182.59 1095.54 273.88 1004.25 273.88 912.95 273.88 912.95 182.59 1004.25 182.59 1095.54 182.59" />
            <rect x="912.95" width="91.3" height="91.29" />
            <rect x="821.66" y="91.29" width="91.29" height="91.3" />
            <polygon points="821.66 182.59 821.66 273.88 730.36 273.88 639.07 273.88 547.77 273.88 456.48 273.88 365.18 273.88 365.18 182.59 273.89 182.59 273.89 273.88 182.59 273.88 91.3 273.88 0 273.88 0 182.59 91.3 182.59 182.59 182.59 182.59 91.29 273.89 91.29 365.18 91.29 456.48 91.29 456.48 0 547.77 0 639.07 0 639.07 91.29 547.77 91.29 547.77 182.59 639.07 182.59 639.07 91.29 730.36 91.29 730.36 182.59 821.66 182.59" />
            <rect width="91.3" height="91.29" />
          </svg>
        </div>
        <section className="section__no-padding--bottom">
          <div className="grid">
            <div className="about__intro">
              <div className="about__description">
                <p>
                  The planning of the registration flow of The NAMM Show, the
                  world's largest music tradeshow while visualizing the final
                  product through Figma.
                </p>
              </div>
              <div className="about__profile--info">
                <p>
                  Design without purpose is just decoration, my canvas of choice
                  is the web. There is nothing I love more than taking a blank
                  canvas, a world full of limitless potential, and transforming
                  it into a digital masterpiece. Yet, within this infinite realm
                  lies the risk of chaos. To navigate it, I wield the guiding
                  principles of User Experience (UX) Best Practices, sketching
                  out a framework through the careful interplay of research and
                  discovery, the precision of goal definition, and the artistry
                  of persona and user journey development. Since 2008 I have
                  been going through these processes leading up to the design
                  phase and ultimately designing and developing these projects.
                </p>
                <p>
                  I currently work as a Front End Developer and UX Designer for
                  NAMM, a music non-profit organization who puts on the largest
                  music tradeshow in the world, The NAMM Show. Outside of work I
                  find myself in the Shopify community, which is where I started
                  building and customizing themes for clients.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section__no-padding--bottom section__no-padding--top">
          <div className="grid">
            <div className="about__profile grid">
              <div className="svg__transition">
                <svg
                  id="Grid_copy"
                  data-name="Grid copy"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1917.19 273.88"
                  style={{ fill: "#fff" }}
                >
                  <polygon points="1917.19 91.29 1917.19 182.59 1825.9 182.59 1825.9 273.88 1734.6 273.88 1643.31 273.88 1552.01 273.88 1460.72 273.88 1460.72 182.59 1552.01 182.59 1552.01 91.29 1460.72 91.29 1460.72 182.59 1369.42 182.59 1369.42 91.29 1278.13 91.29 1278.13 182.59 1369.42 182.59 1369.42 273.88 1278.13 273.88 1186.83 273.88 1095.54 273.88 1095.54 182.59 1095.54 91.29 1186.83 91.29 1186.83 0 1278.13 0 1369.42 0 1460.72 0 1552.01 0 1643.31 0 1734.6 0 1734.6 91.29 1643.31 91.29 1643.31 182.59 1734.6 182.59 1734.6 91.29 1825.9 91.29 1825.9 0 1917.19 0 1917.19 91.29" />
                  <polygon points="1095.54 0 1095.54 91.29 1004.25 91.29 912.95 91.29 912.95 0 1004.25 0 1095.54 0" />
                  <rect x="912.95" y="182.59" width="91.3" height="91.29" />
                  <polygon points="912.95 91.29 912.95 182.59 821.66 182.59 730.36 182.59 730.36 273.88 639.07 273.88 639.07 182.59 639.07 91.29 730.36 91.29 730.36 0 821.66 0 821.66 91.29 912.95 91.29" />
                  <polygon points="639.07 0 639.07 91.29 547.77 91.29 456.48 91.29 456.48 182.59 365.18 182.59 273.89 182.59 273.89 273.88 182.59 273.88 182.59 182.59 91.3 182.59 91.3 91.29 182.59 91.29 182.59 0 273.89 0 365.18 0 456.48 0 547.77 0 639.07 0" />
                  <rect x="456.48" y="182.59" width="91.29" height="91.29" />
                  <rect y="182.59" width="91.3" height="91.29" />
                  <rect width="91.3" height="91.29" />
                </svg>
              </div>

              <div className="about__profile--inner">
                {/* <div className="about__img">
                  <img src="#" loading="lazy" />
                </div> */}

                <div className="about__profile--info">
                  <div className="section-title about__profile--title">
                    <div className="about__profile--title-inner">
                      <h2>Skillset</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about__skills"></div>
          </div>
        </section>

        <section className="section__no-padding--bottom section__no-padding--top">
          <div className="promo__svg">
            <svg
              id="Layer_19"
              data-name="Layer 19"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1917.19 273.89"
            >
              <polygon points="1917.19 182.59 1917.19 273.89 1825.9 273.89 1734.6 273.89 1734.6 182.59 1643.31 182.59 1552.01 182.59 1552.01 273.89 1460.72 273.89 1460.72 182.59 1369.42 182.59 1369.42 91.3 1460.72 91.3 1460.72 0 1552.01 0 1643.31 0 1734.6 0 1825.9 0 1917.19 0 1917.19 91.3 1917.19 182.59" />
              <polygon points="1369.42 0 1369.42 91.3 1278.13 91.3 1186.83 91.3 1095.54 91.3 1004.24 91.3 912.95 91.3 912.95 0 1004.24 0 1095.54 0 1186.83 0 1278.13 0 1369.42 0" />
              <rect x="821.65" y="182.59" width="91.3" height="91.3" />
              <polygon points="821.65 0 821.65 91.3 730.36 91.3 730.36 182.59 639.06 182.59 639.06 91.3 547.77 91.3 547.77 182.59 456.47 182.59 456.47 91.3 365.18 91.3 365.18 182.59 273.89 182.59 273.89 273.89 182.59 273.89 182.59 182.59 91.3 182.59 91.3 273.89 0 273.89 0 182.59 0 91.3 0 0 91.3 0 182.59 0 273.89 0 365.18 0 456.47 0 547.77 0 639.06 0 730.36 0 821.65 0" />
            </svg>
          </div>
        </section>
      </div>
    </>
  );
}

export default FelipePantoneProject;
