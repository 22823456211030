import React from "react";
import SlideApolis from "./SlideApolis";
import SlidePgs from "./SlidePgs";
import SlideMusic from "./SlideMusic";
import SlideCloud from "./SlideCloud";
import Slider from "./Slider"; // Import the Slider component
import Slide from "./Slide";
import SlideLightning from "./SlideLightning";

function Projects() {
  // const slidesData = [
  //   {
  //     key: 1,
  //     content: (
  //       <div>
  //         <h2>Project 1</h2>
  //         <p>Description for Project 1</p>
  //         <img src="url-to-image-1.jpg" alt="Project 1" />
  //         {/* Add unique content for Slide 1 */}
  //       </div>
  //     ),
  //   },
  //   {
  //     key: 2,
  //     content: (
  //       <div>
  //         <h2>Project 2</h2>
  //         <p>Description for Project 2</p>
  //         <img src="url-to-image-2.jpg" alt="Project 2" />
  //         {/* Add unique content for Slide 2 */}
  //       </div>
  //     ),
  //   },
  //   // Add more slide data objects with unique content as needed
  // ];


  return (
    <div className="work-page">
      <div className="work__title">
        <div className="text-mask">
          <div className="text-line">
            <h1>Work</h1>
          </div>
        </div>
      </div>
      <SlideLightning />
      <SlideMusic />
      <SlidePgs />
      <SlideApolis />
      <SlideCloud />
    </div>
  );
}

export default Projects;
