import React from "react";

function Tools() {
  return (
    <section id="tools">
      <div className="grid">
        <div className="tools">
          <div className="tools__inner">
            <div className="tools__collage">Add logo stuff here</div>
            <div className="tools__content">
              <div className="tools__title">
                <h2>
                  <div className="text-mask">
                    <span>Skillset &</span>
                  </div>
                  <div className="text-mask">
                    <span>Technology Used</span>
                  </div>
                </h2>
              </div>
              <div className="tools__description">
                <p>
                  Creative strategy development involves a meticulous approach,
                  starting with a deep understanding of our clients' goals and
                  target audience. We then weave together innovative concepts,
                  aligning them with our clients' brand and vision, to create a
                  powerful narrative that resonates. From ideation to execution,
                  we craft immersive and captivating experiences that leverage
                  the latest technology to leave a lasting impression.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tools;
