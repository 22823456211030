import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { gsap, Power3 } from "gsap";
import { TailSpin } from "react-loader-spinner";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import NotFound from "./NotFound";
import Navigation from "./Navigation";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Projects from "./Projects";
import RealEstateSolutions from "./RealEstateSoultions";
import FelipePantoneProject from "./FelipePantoneProject";
import SkyIsTheLimit from "./SkyIsTheLimit";
import CactusClint from "./CactusClint";
import Sitemap from "./Sitemap";
import PrivacyPolicy from "./PrivacyPolicy";
import Contact from "./Contact";

function App() {
  const navLinksRef = useRef(null);

  return (
    <>
      <Router>
        <div className="site-container">
          <Navigation />
          <div className="site-push">
            <PageTransition navLinksRef={navLinksRef}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/work" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route
                  path="/projects/pgs-homeloans"
                  element={<RealEstateSolutions />}
                />
                <Route
                  path="/projects/namm-registration"
                  element={<FelipePantoneProject />}
                />
                <Route
                  path="/projects/sky-is-the-limit"
                  element={<SkyIsTheLimit />}
                />
                <Route path="/projects/apolis" element={<CactusClint />} />
                <Route path="/sitemap" element={<Sitemap />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </PageTransition>
          </div>
        </div>
      </Router>
    </>
  );
}

const PageTransition = ({ children, navLinksRef }) => {
  const location = useLocation();
  const [transitionIn, setTransitionIn] = useState(false);
  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    const storedIsFirstVisit = sessionStorage.getItem("isFirstVisit");
    if (!storedIsFirstVisit) {
      sessionStorage.setItem("isFirstVisit", "true");
    } else {
      setFirstVisit(false);
    }
  }, []);

  useEffect(() => {
    if (transitionIn) {
      gsap.fromTo(
        ".page-transition",
        { y: "100%" },
        { y: "0%", ease: "power2.out", duration: 0.5 }
      );

      if (firstVisit) {
        gsap.fromTo(
          navLinksRef.current.children,
          { y: 100, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            stagger: 0.2,
            duration: 1,
            ease: Power3.easeOut,
          }
        );
      }
    }
  }, [transitionIn, firstVisit, navLinksRef]);

  useEffect(() => {
    // Trigger the transition animation
    setTransitionIn(true);

    // Remove the dark-side class when transitioning to a new page
    document.body.classList.remove("dark-side");

    // Reset the transition flag after the animation duration
    const timeout = setTimeout(() => {
      setTransitionIn(false);
    }, 500); // Adjust the duration as per your animation

    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <div className={`page-transition ${transitionIn ? "transition-in" : ""}`}>
      {firstVisit && (
        <div
          className="tailspin-container"
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius={1}
            visible={true}
          />
        </div>
      )}
      {children}
    </div>
  );
};

export default App;
