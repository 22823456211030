import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap, Power3 } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import pgs from "../src/images/pgs-main-bg.jpg";
import pgsCard from "../src/images/pgs-main.jpg";
import namm from "../src/images/namm-main-bg.jpg";
import nammCard from "../src/images/namm-main.jpg";
import blueImg from "../src/images/blue-card.jpg";
import greenImg from "../src/images/ground-3.jpg";
import apolisCard from "../src/images/apolis-main.jpg";
import blue from "../src/images/blue.jpg";
import green from "../src/images/sky-collage-4.jpg";
import apolis from "../src/images/apolis-main-bg-2.jpg";

gsap.registerPlugin(ScrollTrigger);

function Work() {
  const [activeProject, setActiveProject] = useState(0);
  const workRef = useRef(null);
  const cardImagesRef = useRef(null);
  const textRef = useRef(null);

  const projectImages = [pgs, namm, green, apolis];
  const cardImages = [pgsCard, nammCard, greenImg, apolisCard];
  const cardTitles = [
    "PGS Homeloans App",
    "The NAMM Show Registration",
    "Celestial Exploration",
    "Apolis Shopify Project",
  ];
  const cardDescriptions = [
    "An app built in Ruby to facilitate lender information and resources to staff and allow for the lenders and brokers to provide ratings.",
    "A web app for the world's largest music tradeshow. Every registrant for the event goes through this portal.",
    "An interactive learning tool to discover and understand the different cloud types that can be found at various elevations.",
    "Improving the UX of a Shopify site to communicate the customization of a product done by an end user. ",
  ];
  const cardButtonTitles = ["Home Lender", "Music", "Clouds", "E-commerce"];
  const cardLinks = [
    "https://hub.pgshomeloans.com/",
    "https://registration.namm.org/",
    "https://www.figma.com/proto/sMqtmZ5pwn4W8IKDOyBk3e/Sky-Is-The-Limit?page-id=5453%3A371343&type=design&node-id=5453-371355&viewport=147%2C60%2C0.13&t=RDEICfhdBwdLvVzl-1&scaling=scale-down&starting-point-node-id=5601%3A373411t",
    "https://apolisglobal.com/",
  ];

  const handleProjectClick = (index) => {
    setActiveProject(index);
  };

  useEffect(() => {
    const workElement = workRef.current;
    const cardImagesElement = cardImagesRef.current;

    const cardElement = workElement.querySelector(".work__card");
    const linksElement = workElement.querySelector(".work__links");
    const imageElement = workElement.querySelector(".work__image");

    // Reset initial state for .work__card--img, .work__card--title, and .work__card--description
    gsap.set(".work__card--img, .work__card--title, .work__card--description", {
      y: 0,
      opacity: 1,
    });

    gsap.registerPlugin(TextPlugin);

    const textElement = textRef.current;

    if (textElement) {
      const spans = textElement.querySelectorAll(".text-line");
      let animationTriggered = false;

      gsap.set(spans, { opacity: 0 });

      ScrollTrigger.create({
        trigger: spans,
        start: "top 90%", // Trigger animation when 300px from the bottom of the viewport
        onEnter: () => {
          if (!animationTriggered) {
            gsap.fromTo(
              spans,
              { rotation: 5, y: 100, opacity: 0 },
              {
                rotation: 0,
                y: 0,
                opacity: 1,
                stagger: 0.2,
                duration: 1,
                ease: Power3.easeOut,
                delay: 0.5, // Add a delay of 0.5s
              }
            );
            animationTriggered = true;
          }
        },
      });
    }
  }, []);

  return (
    <section ref={workRef}>
      <div className="grid">
        <div className="work">
          <div className="work__title">
            <div className="text-mask">
              <div className="text-line">
                <h2>Work</h2>
              </div>
            </div>

          </div>
          <div className="work__links">
            {projectImages.map((_, index) => (
              <button
                key={index}
                className={`work__btn ${
                  index === activeProject ? "active" : ""
                }`}
                onClick={() => handleProjectClick(index)}
              >
                {cardButtonTitles[index]}
              </button>
            ))}
          </div>
          <div className="work__image--container">
            <div className="work__card">
              <div className="work__card--inner" ref={cardImagesRef}>
                <div className="work__card--img">
                  {cardImages.map((image, index) => (
                    <img
                      key={index}
                      className={`work__card--img-item ${
                        index <= activeProject ? "active" : ""
                      }`}
                      src={image}
                      alt={`Card ${index + 1}`}
                    />
                  ))}
                </div>
                <div className="work__card--title">
                  {cardTitles[activeProject]}
                </div>
                <div className="work__card--description">
                  <p>{cardDescriptions[activeProject]} </p>
                </div>
                <div className="work__card--cta">
                  <button className="inverse">
                    <a href={cardLinks[activeProject]}>View Project</a>
                  </button>
                </div>
              </div>
            </div>
            <div className="work__image">
              {projectImages.map((image, index) => (
                <img
                  key={index}
                  className={`work__image-item ${
                    index <= activeProject ? "active" : ""
                  }`}
                  src={image}
                  alt={`Project ${index + 1}`}
                  loading="lazy"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
