// Slider.js
import React, { useEffect, useRef } from "react";

const Slider = ({ slides }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      sliderRef.current.style.transform = `translateY(-${scrollY}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="slider" ref={sliderRef}>
      {slides}
    </div>
  );
};

export default Slider;
