// Slide.js
import React from "react";
import kitchen from "./images/kitchen.jpg";
import pdf from './images/believe-in-music__case-study.pdf';

const SlideBIM = ({ title, description, backgroundImage, image }) => {
  const slideStyle = {
    position: "relative",
  };

  const contentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    background: "rgba(255, 255, 255, 0.8)",
    padding: "27px 18px 18px",
  };

  return (
    <div className="slide bim-slide slide-border__dark" style={slideStyle}>
      <div className="content" style={contentStyle}>
        <div className="slide__number">01</div>
        <h2>Believe In Music</h2>
        <p>
            An online event platform designed to connect and celebrate the global music community through live-streamed content, interactive networking spaces, and educational resources.
        </p>
        <button className="inverse">
          <a target="_blank" rel="nofollow" href={pdf}>
            View Case Study
          </a>
        </button>
      </div>
    </div>
  );
};

export default SlideBIM;
