import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();
  const [isFormValid, setIsFormValid] = useState(true); // Track form validation
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Track success message

  const sendEmail = (e) => {
    e.preventDefault();

    // Validate form inputs
    if (
      !form.current.user_name.value ||
      !form.current.user_email.value ||
      !form.current.message.value
    ) {
      setIsFormValid(false);
      return;
    }

    emailjs
      .sendForm(
        "service_ivbajr8",
        "template_974t0ka",
        form.current,
        "9hYtUxH6QgTF-EMvi"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccessMessage(true); // Show success message
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      {showSuccessMessage ? (
        <div className="success-message">Message sent successfully!</div>
      ) : (
        <form ref={form} onSubmit={sendEmail}>
          {!isFormValid && (
            <div className="error-message">Please fill in all fields.</div>
          )}
          <div className="form__row">
            <label>Name</label>
            <input type="text" name="user_name" />
          </div>
          <div className="form__row">
            <label>Email</label>
            <input type="email" name="user_email" />
          </div>
          <div className="form__row">
            <label>Message</label>
            <textarea name="message" />
          </div>
          <div className="form__submit">
            <input type="submit" value="Send" />
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
