import React from "react";

function PrivacyPolicy() {
  return (
    <section>
      <div className="grid">
        <div className="section__content">
          <div className="section__title--main center no-hero">
            <h1>Privacy Policy</h1>
          </div>
        </div>
        <div className="section__content">
          <div className="content__basic">
            <p>Last Updated: September 1, 2023</p>

            <p>
              This Privacy Policy outlines how{" "}
              <a href="https://www.clintwes.com">clintwes.com</a> ("we," "us," or
              "our") collects, uses, and protects the information you provide when
              you use our website. We are committed to safeguarding your privacy
              and ensuring that your personal information is handled with care and
              in accordance with applicable data protection laws.
            </p>

            <p>
              By using our website, you agree to the terms and conditions outlined
              in this Privacy Policy.
            </p>

            <h3>Information We Collect</h3>

            <p>We collect and process the following types of information:</p>

            <ol>
              <li>
                <strong>Contact Information:</strong> When you use our contact
                form, we may collect your name and email address to respond to
                your inquiries.
              </li>

              <li>
                <strong>Message Content:</strong> The contact form may also
                include a textarea where you can provide additional information or
                comments.
              </li>
            </ol>

            <h3>How We Use Your Information</h3>

            <p>We use the information collected for the following purposes:</p>

            <ol>
              <li>
                {" "}
                <strong>Responding to Inquiries:</strong> We use the contact
                information provided to respond to your inquiries and provide
                assistance as requested.
              </li>

              <li>
                <strong>Improving Our Services:</strong> We may analyze the
                content of messages to improve our products and services.
              </li>
            </ol>

            <h3>Data Retention</h3>

            <p>
              We will retain your personal information for as long as necessary to
              fulfill the purposes for which it was collected. If you no longer
              want us to use your information, please contact us using the
              information provided in the "Contact Us" section below, and we will
              delete your data in accordance with applicable law.
            </p>

            <h3>Disclosure of Information</h3>

            <p>
              We do not sell, trade, or otherwise transfer your personally
              identifiable information to third parties without your consent,
              except in the following circumstances:
            </p>

            <ol>
              <li>
                <strong>Service Providers:</strong> We may share your information
                with third-party service providers who assist us in operating our
                website or providing services to you.
              </li>

              <li>
                <strong>Legal Requirements:</strong> We may disclose your
                information if required by law, regulation, or legal process.
              </li>
            </ol>

            <h3>Security</h3>

            <p>
              We are committed to ensuring the security of your personal
              information. We implement reasonable measures to protect your data
              from unauthorized access, disclosure, alteration, or destruction.
            </p>

            <h3>Your Rights</h3>

            <p>
              You have the following rights concerning your personal information:
            </p>

            <ol>
              <li>
                <strong>Access:</strong> You may request access to the personal
                information we hold about you.
              </li>

              <li>
                <strong>Correction:</strong> You may request that we correct or
                update your personal information.
              </li>

              <li>
                <strong>Deletion:</strong> You may request the deletion of your
                personal information, subject to legal requirements.
              </li>

              <li>
                <strong>Objection:</strong> You may object to the processing of
                your personal information.
              </li>
            </ol>

            <h3>Changes to this Privacy Policy</h3>

            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. The revised policy will be posted on our
              website, and the date of the last update will be indicated at the
              top of the policy.
            </p>

            <h3>Contact Us</h3>

            <p>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or the way we handle your personal information,
              please contact us at:
            </p>

            <p>hello@clintwes.com</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
