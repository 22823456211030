// Slide.js
import React from "react";
import apolis from "./images/apolis-work.jpg";

const SlideApolis = ({ title, description, backgroundImage, image }) => {
  const slideStyle = {
    position: "relative",
  };

  // const contentStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   textAlign: "center",
  // };

  return (
    <div className="slide slide-apolis slide-border__dark" style={slideStyle}>
      <div className="content center-content">
        <div className="slide__3col-centered">
          <div className="slide__3col-centered--title2">
            <div className="slide__number">05</div>
            <h2 className="slide__title">Apolis</h2>
            <button>
              <a
                target="_blank"
                rel="nofollow"
                href="https://apolisglobal.com/"
              >
                View Website
              </a>
            </button>
          </div>

          <div className="slide__3col-centered--info2">
            <div className="slide__3col-centered--img3">
              <img src={apolis} alt="Cloud 1" />
            </div>
          </div>

          <div className="slide__3col-centered--description">
            <p>
              Improving the UX of a Shopify site to communicate the
              customization of a product done by an end user.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideApolis;
