import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

import Logo from "./images/clint-wes-logo-4.png";

function Navigation() {
  const navLinksRef = useRef(null);
  const imageRef = useRef(null);
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen((prevState) => !prevState);
    animateLinks();
  };

  const animateLinks = () => {
    const navLinks = navLinksRef.current.querySelectorAll(".nav ul li");

    gsap.from(navLinks, {
      opacity: 0,
      y: -20,
      duration: 0.2,
      stagger: 0.2,
      ease: "power3.out",
    });
  };

  useEffect(() => {
    if (navOpen) {
      document.body.classList.add("nav-open"); // Add class to body when navigation is open
    } else {
      document.body.classList.remove("nav-open"); // Remove class from body when navigation is closed
    }
  }, [navOpen]);

  return (
    <div className="navigation__bar">
      <nav className={`nav ${navOpen ? "nav--open" : ""}`}>
        <div className="nav__logo">
          <Link className="nav-link" to="/" onClick={() => setNavOpen(false)}>
            <img ref={imageRef} src={Logo} alt="San Diego" />
          </Link>
        </div>
        <div className="nav__links" ref={navLinksRef}>
          <div className="nav__links--inner">
            <ul>
              <li>
                <Link className="nav-link" to="/work" onClick={toggleNav}>
                  Work
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/about" onClick={toggleNav}>
                  About
                </Link>
              </li>
              {/* <li>
                <Link className="nav-link" to="/blog" onClick={toggleNav}>
                  Blog
                </Link>
              </li> */}
              <li>
                <Link className="nav-link" to="/contact" onClick={toggleNav}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <button className="nav__toggle" onClick={toggleNav}>
          <span></span>
        </button>
      </nav>
    </div>
  );
}

export default Navigation;
