import React, { useEffect, useState, useRef } from "react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/all";
import sanDiegoImage from "../src/images/san-diego-2.5.jpg";
import { TextPlugin } from "gsap/TextPlugin";

function CorePrincipals() {
  const [isDarkSide, setIsDarkSide] = useState(false);
  const rowRefs = useRef([]);
  const textRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(TextPlugin);

    const textElement = textRef.current;

    if (textElement) {
      const spans = textElement.querySelectorAll(".text-line");
      let animationTriggered = false;

      gsap.set(spans, { opacity: 0 });

      ScrollTrigger.create({
        trigger: spans,
        start: "top 90%", // Trigger animation when 300px from the bottom of the viewport
        onEnter: () => {
          if (!animationTriggered) {
            gsap.fromTo(
              spans,
              { rotation: 5, y: 100, opacity: 0 },
              {
                rotation: 0,
                y: 0,
                opacity: 1,
                stagger: 0.2,
                duration: 1,
                ease: Power3.easeOut,
                delay: 0.5, // Add a delay of 0.5s
              }
            );
            animationTriggered = true;
          }
        },
      });
    }
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercentage >= 30) {
        document.body.classList.add("dark-side");
      } else {
        document.body.classList.remove("dark-side");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };


  }, []);

  return (
    <section>
      <div className="grid">
        <div className="core-principals">
          <div className="section-title core-principals__title">
            <div className="text-mask">
              <div className="text-line">
                <h2>What I do</h2>
              </div>
            </div>
          </div>
          <div className="core-principals__content">
            <div className="grid row" ref={(el) => (rowRefs.current[0] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">01</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Product Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Designing and scaling products that bridge creative innovation with data insights. Delivering consistent, user-focused solutions that align with business needs and enhance brand identity across multiple platforms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">02</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>UX/UI Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                        Crafting user-centric designs through strategy, prototyping, and iteration, leveraging deep experience in Figma. Ensuring cohesive, intuitive user interfaces that are adaptable across diverse brands and digital products.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[1] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">03</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Mobile Experience Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                      Developing mobile-first, responsive designs that adapt seamlessly across various devices. Addressing cross-platform navigation, performance, and usability to deliver consistent and user-friendly mobile experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">04</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>User-Centered Design</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                      Applying human-centered interaction principles, using user research, testing, and behavioral insights to design experiences that deeply resonate with users and meet their needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[2] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">05</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Creative Strategy</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                      Translating brand visions into engaging and impactful experiences. Strategizing and executing design concepts to align thematic consistency across brands and digital touchpoints.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column span-6">
                <div className="flat-card__number">06</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Universal User Experiences</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                      Ensuring accessibility and inclusive design across all products, adhering to industry best practices and Universal Design principles. Creating experiences usable by diverse user bases without the need for adaptation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid row" ref={(el) => (rowRefs.current[3] = el)}>
              <div className="column span-6">
                <div className="flat-card__number">07</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Front End Development</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                      Bringing design systems to life by developing functional, scalable UI components. Utilizing modern frameworks like SASS, JavaScript, and Figma integrations to maintain responsive and accessible designs across platforms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column span-6">
                <div className="flat-card__number">08</div>
                <div className="flat-card__wrap">
                  <div className="flat-card">
                    <div className="flat-card__icon"></div>
                    <div className="flat-card__title">
                      <h3>Search Engine Optimization</h3>
                    </div>
                    <div className="flat-card__description">
                      <p>
                      Enhancing digital content with a data-driven approach to increase engagement and visibility. Optimizing user flows and content with SEO strategies that align with broader user acquisition and retention goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </section>
  );
}

export default CorePrincipals;
