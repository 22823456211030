import React from "react";
import { Helmet } from "react-helmet-async";
import ContactForm from "./ContactForm";

function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Clint Evans</title>
        <link rel="canonical" href="https://www.clintwes.com/contact" />
        <meta
          name="description"
          content="Get in contact with Clint Evans with any questions or inquiries regarding freelance projects."
        />
      </Helmet>
      <section className="dark-side">
        <div className="grid">
          <div className="section__content">
            <div className="section__title--main center no-hero">
              <h1>Contact</h1>
            </div>
          </div>
          <div className="content__basic island">
            <ContactForm />
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
