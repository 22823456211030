// Slide.js
import React from "react";

const Slide = ({ title, description, backgroundImage, image }) => {
  const slideStyle = {
    height: "100vh",
    position: "relative",
  };

  const contentStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };

  return (
    <div className="slide" style={slideStyle}>
      <div className="content" style={contentStyle}>
        <h2>{title}</h2>
        <p>{description}</p>
        <img src={image} alt="Slide" />
      </div>
    </div>
  );
};

export default Slide;
