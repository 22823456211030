import React from "react";
import { Link } from "react-router-dom";
import InstagramIcon from "./images/instagram-01.svg";
import BehanceIcon from "./images/behance-01.svg";
import LinkedInIcon from "./images/linkedin-01.svg";
import XIcon from "./images/x-01.svg";
import EmailSignupForm from "./EmailSignupForm";
import CLogo from "./images/clint-wes-c__logo.png";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="grid">
        <div className="footer__inner">
          {/* <div className="footer__contact--form">
            <EmailSignupForm />
          </div> */}
          <div className="footer__c-logo">
            <img src={CLogo} alt="Clint Wes" title="Clint Wes" />
          </div>
          {/* <div className="footer__mantra">
            <p>
              A UX Designer & Front End Developer that integrates seamless
              digital experiences, merging creativity with functionality.
            </p>
          </div> */}
          <div className="footer__copyright--links">
            <ul>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              {/* <li>
                <Link to="/sitemap">Sitemap</Link>
              </li> */}
            </ul>
          </div>
          <div className="footer__copyright">
            <div className="footer__copyright--info">
              © Clint Wes {currentYear}, All Rights Reserved.
            </div>
            <div className="footer__social--links">
              <ul>
                <li>
                  <a
                    href="https://twitter.com/clint_wes"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src={XIcon}
                      alt="X (formerly Twitter)"
                      title="X (formerly Twitter)"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/clint_wes"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src={InstagramIcon}
                      alt="Instagram"
                      title="Instagram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/clint-evans/"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img src={LinkedInIcon} alt="LinkedIn" title="LinkedIn" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
