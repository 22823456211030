// Slide.js
import React from "react";
import showCloud2 from "./images/cloud2.jpg";
import showCloud7 from "./images/cloud7.jpg";

const SlideCloud = ({ title, description, backgroundImage, image }) => {
  const slideStyle = {
    position: "relative",
  };

  // const contentStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   textAlign: "center",
  // };

  return (
    <div className="slide" style={slideStyle}>
      <div className="slide__content">
        <div className="slide__3col-centered">
          <div className="slide__3col-centered--img1">
            <img src={showCloud7} alt="Cloud 1" />
          </div>

          <div className="slide__3col-centered--info">
            <div className="slide__number">05</div>
            <div className="slide__3col-centered--content">
              <h2>The Sky Is The Limit</h2>
              <p>
                An interactive learning tool to discover and understand the different cloud types that can be found at various elevations.
              </p>
              <button>
                <a
                  target="_blank"
                  rel="nofollow"
                  href="https://www.figma.com/proto/sMqtmZ5pwn4W8IKDOyBk3e/Sky-Is-The-Limit?page-id=5453%3A371343&type=design&node-id=5453-371355&viewport=147%2C60%2C0.13&t=RDEICfhdBwdLvVzl-1&scaling=scale-down&starting-point-node-id=5601%3A373411&mode=design"
                >
                  View Figma Prototype
                </a>
              </button>
            </div>
          </div>

          <div className="slide__3col-centered--img2">
            <img src={showCloud2} alt="Cloud 2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideCloud;
